import { api } from './'
import * as Actions from "../redux/constants/Auth";

const usersService = {}
const config = () => {
	return {
		headers: {'Authorization': `${localStorage.getItem(Actions.AUTH_TOKEN)}`}
	}
}

usersService.model = {
		id: 0,
		update: "",
		id_perfil: 0,
		ativo: 0,
		nome: "",
		sobrenome: "",
		usuario: "",
		email: "",
		password: "",
		texto: "",
		cpf:"",
		matricula:"",
		funcao:""
}

usersService.getUsers = async function () {
	let result = null;
	await api.get('/api/users/usuariosSistema', config())
			.then(res => {
					result = {data: res.data, error: false, type: res.status}
			})
			.catch(error => {
					console.log('error', error)
					result = {data: null, error: true, type: error}
			})
	return result;
}

usersService.getUserId = async function (id) {
	let result = null;
	await api.get(`/api/users/${id}`, config())
			.then(res => {
				result = {data: res.data, error: false, type: res.status}
			})
			.catch(error => {
				console.log('error', error)
				result = {data: null, error: true, type: error}
			})
	return result;
}

usersService.postUser = async function (data) {
	let result = null;
	await api.post(`/api/users/`, data)
			.then(res => {
					result = {data: res.data, error: false, type: res.status}
			})
			.catch(error => {
					console.log('error', error)
					result = {data: null, error: true, type: error}
			})
	return result;
}

usersService.postRecoverPass = async function (data) {
	let result = null;
	await api.post(`/api/recover-pass/`, data)
			.then(res => {
					result = {data: res.data, error: false, type: res.status}
			})
			.catch(error => {
					console.log('error', error)
					result = {data: null, error: true, type: error}
			})
	return result;
}

usersService.checkHashIsValid = async function (data) {
	let result = null;
	await api.post(`/api/recover-pass/verify-hash`, data)
			.then(res => {
					result = {data: res.data, error: false, type: res.status}
			})
			.catch(error => {
					console.log('error', error)
					result = {data: null, error: true, type: error}
			})
	return result;
}

usersService.postConfirmEmail = async function (data) {
	let result = null;
	await api.post(`/api/users/confirm-email`, data)
			.then(res => {
					result = {data: res.data, error: false, type: res.status}
			})
			.catch(error => {
					console.log('error', error)
					result = {data: null, error: true, type: error}
			})
	return result;
}

usersService.putUserPass = async function (data) {
	let result = null;
	await api.put(`/api/recover-pass/change-pass`, data)
			.then(res => {
					result = {data: res.data, error: false, type: res.status}
			})
			.catch(error => {
					console.log('error', error)
					result = {data: null, error: true, type: error}
			})
	return result;
}


usersService.putUserPassOld = async function (data) {
	let result = null;
	await api.put(`/api/users/password`, data)
			.then(res => {
					result = {data: res.data, error: false, type: res.status}
			})
			.catch(error => {
					console.log('error', error)
					result = {data: null, error: true, type: error}
			})
	return result;
}

export default usersService
