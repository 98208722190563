import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';
import { DataUserProvider } from '../../context/DataUser';

export const AppViews = () => {
  return (
    <DataUserProvider>
      <Suspense fallback={<Loading cover='page' />}>
        <Switch>
          <Route
            path={`${AUTH_PREFIX_PATH}/login`}
            component={lazy(() => import(`./authentication/login-2`))}
          />
          {/* <Route path={`${AUTH_PREFIX_PATH}/change`} component={lazy(() => import(`./authentication/change-password`))} /> */}
          <Route
            path={`${AUTH_PREFIX_PATH}/reset`}
            component={lazy(() => import(`./authentication/forgot-password`))}
          />
          <Route
            path={`${AUTH_PREFIX_PATH}/registerUser`}
            component={lazy(() => import(`./authentication/registerUser`))}
          />
          <Route
            path={`${AUTH_PREFIX_PATH}/feedback`}
            component={lazy(() =>
              import(`./authentication/feedback-register-user`)
            )}
          />
          <Route
            path={`${AUTH_PREFIX_PATH}/confirmEmail/:token`}
            component={lazy(() => import(`./authentication/confirmEmail`))}
          />
          <Route
            path={`${AUTH_PREFIX_PATH}/feedbackRecoverPass`}
            component={lazy(() => import(`./authentication/recoverPass`))}
          />
          <Route
            path={`${AUTH_PREFIX_PATH}/newPass`}
            component={lazy(() => import(`./authentication/new-pass`))}
          />
           <Route
            path={`${AUTH_PREFIX_PATH}/twoFactors`}
            component={lazy(() => import(`./authentication/two-factors`))}
          />
          <Redirect
            from={`${AUTH_PREFIX_PATH}`}
            to={`${AUTH_PREFIX_PATH}/login`}
          />
        </Switch>
      </Suspense>
    </DataUserProvider>
  );
};

export default AppViews;
