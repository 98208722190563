import { api } from './'
import * as Actions from '../redux/constants/Auth'

const loginService = {}

const config = () => {
  return {
    headers: { Authorization: `${localStorage.getItem(Actions.AUTH_TOKEN)}` },
  }
}

loginService.getUsers = async function () {
  let result = null
  await api
    .get('/api/users/get')
    .then((res) => {
      result = { data: res.data, error: false, type: res.status }
    })
    .catch((error) => {
      console.log('error', error)
      result = {
        data: null,
        error: true,
        type: error,
        status: error?.response?.status,
      }
    })
  return result
}

loginService.login = async function (data) {
  //console.log('loginService.login', data);
  let result = null
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }
  await api
    .post(`/api/login/`, data, config)
    .then((res) => {
      result = { data: res.data, error: false, status: res.status }
    })
    .catch((error) => {
      console.log('error', error)
      result = {
        data: null,
        error: true,
        type: error,
        status: error?.response?.status
      }
    })
  return result
}

loginService.getToken = async function (data) {
  let result = null
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: data.token,
    },
  }
  await api
    .post(`/api/auth/`, data, config)
    .then((res) => {
      result = { data: res.data, error: false, status: res.status }
    })
    .catch((error) => {
      console.log('error', error)
      result = {
        data: null,
        error: true,
        type: error,
        status: error.status,
      }
    })
  return result
}

loginService.refreshToken = async function () {
  let result = null
  await api
    .get('/api/login/refresh', config())
    .then((res) => {
      result = { data: res.data, error: false, type: res.status }
    })
    .catch((error) => {
      console.log('error', error)
      result = {
        data: null,
        error: true,
        type: error,
        status: error?.response?.status,
      }
    })
  return result
}

loginService.validateLogged = async function () {
  let result = null
  await api
    .get('/api/login/logged', config())
    .then((res) => {
      result = { data: res.data, error: false, status: res.status }
    })
    .catch((error) => {
      console.log('error', error)
      result = {
        data: null,
        error: true,
        type: error,
        status: error.response.status,
      }
    })
  return result
}

loginService.checkHashIsValidLoggin = async function (data) {
	let result = null;
	await api.post(`/api/login/verify-hash`, data)
			.then(res => {
					result = {data: res.data, error: false, type: res.status}
			})
			.catch(error => {
					console.log('error', error)
					result = {data: null, error: true, type: error}
			})
	return result;
}

loginService.setPost = function (data) {
  return fetch({
    url: '/posts',
    method: 'post',
    data: data,
  })
}

export default loginService
